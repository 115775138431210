/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import Link, { type LinkProps } from 'next/link'

import { ResolvedImage } from '@/components/utility/image'

interface CircleBaseProps {
  backgroundColor: string
  className?: string
  fill?: boolean
  size?: number
  sizes?: string
  ['data-testid']?: string
  onClick?: () => void
}

type CircleBaseWithSize = CircleBaseProps & Partial<LinkProps>

interface CircleImageProps {
  src: string
  alt: string
}

interface CircleComponentProps {
  component: JSX.Element
}

type CircleType = CircleImageProps | CircleComponentProps

export type CircleTileProps = CircleBaseWithSize & CircleType

const getCircleSize = (size: number | undefined) =>
  size === undefined
    ? undefined
    : {
        height: size,
        width: size,
      }

const isCircleImage = (props: CircleType): props is CircleImageProps =>
  'src' in props && !!props.src

export const CircleTile = (props: CircleTileProps) => {
  const { backgroundColor, fill, size, sizes, href, className, ...restProps } =
    props
  const wrapperClass = `relative flex shrink-0 rounded-half ${className ?? ''}`
  const wrapperStyle = { ...getCircleSize(size), backgroundColor }
  const imageSize = fill ? undefined : size
  const testId = props['data-testid']

  const inner = (
    <>
      {isCircleImage(props) ? (
        <div className='relative m-[16%] flex size-[68%] place-items-center'>
          <ResolvedImage
            className='w-full bg-transparent'
            src={props.src}
            alt={props.alt}
            title={props.alt}
            width={imageSize}
            height={imageSize}
            data-testid={testId && `${testId}-img`}
            fill={fill}
            sizes={sizes}
          />
        </div>
      ) : (
        props.component
      )}
    </>
  )

  if (href) {
    return (
      <Link
        prefetch={false}
        href={href}
        {...restProps}
        className={wrapperClass}
        style={wrapperStyle}
        data-testid={testId}
      >
        {inner}
      </Link>
    )
  }

  return (
    <div
      className={`${wrapperClass} ${
        props.onClick ? 'cursor-pointer' : 'cursor-auto'
      }`}
      style={wrapperStyle}
      data-testid={testId}
      onClick={props.onClick}
    >
      {inner}
    </div>
  )
}
